@if (gameRulesLinkModeVisible$ | async) {
  <span
    class="link"
    (click)="openGameRules()"
    (keyup.enter)="openGameRules()"
    tabindex="0">
    {{ 'footer.gameRules' | translate }}
  </span>
}
@if (termsLinkModeVisible$ | async) {
  <span
    class="link"
    tabindex="0"
    (click)="openTerms()"
    (keyup.enter)="openTerms()">
    {{ 'footer.terms' | translate }}
  </span>
}
@if (faqLinkModeVisible$ | async) {
  <span class="link" tabindex="0" (click)="openFaq()" (keyup.enter)="openFaq()">
    {{ 'footer.faq' | translate }}
  </span>
}
