<div class="container">
  <div class="background"
       [class.large]="hidePlayTab$ | async"
       [class.right]="(selectedTab$ | async) === tabs.PLAY">
  </div>
  <div class="tab"
       tabindex="0"
       [class.selected]="(selectedTab$ | async) === tabs.INFO"
       (keyup.enter)="selectTab(tabs.INFO)"
       (click)="selectTab(tabs.INFO)">
    {{ "tabs.info" | translate }}
  </div>
  @if ((hidePlayTab$ | async) === false) {
    <div class="tab"
         tabindex="0"
         [class.selected]="(selectedTab$ | async) === tabs.PLAY"
         (keyup.enter)="selectTab(tabs.PLAY)"
         (click)="selectTab(tabs.PLAY)">
      {{ "tabs.play" | translate }}
    </div>
  }
</div>
