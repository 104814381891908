import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { SessionService } from '@services/session.service';
import { SpinService } from '@services/spin.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-next-spin-info',
  templateUrl: './next-spin-info.component.html',
  styleUrls: ['./next-spin-info.component.scss'],
})
export class NextSpinInfoComponent implements OnInit {
  countdown = '';

  constructor(
    private session: SessionService,
    private spinService: SpinService,
  ) {}

  ngOnInit() {
    this.calculateCountdown();
    interval(1000)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.calculateCountdown();
      });
  }

  private calculateCountdown() {
    this.spinService.spinInfo$
      .pipe(untilDestroyed(this))
      .subscribe((spinInfo) => {
        if (!spinInfo) {
          this.countdown = '';
          return;
        }
        let seconds =
          spinInfo!.nextSpinInSeconds -
          Math.floor((new Date().getTime() - spinInfo.startTs) / 1000);
        if (seconds <= 0) {
          this.spinService.updateShowNextSpinInfo(false);
          this.countdown = '0S';
          return;
        }
        const days = Math.floor(seconds / (24 * 3600));
        seconds -= days * 24 * 3600;
        const hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        const minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        let result = '';
        if (days > 0) result += days + 'D:';
        if (hours > 0 || result)
          result += String(hours).padStart(days > 0 ? 2 : 1, '0') + 'H:';
        if (minutes > 0 || result)
          result += String(minutes).padStart(hours > 0 ? 2 : 1, '0') + 'M:';
        result += String(seconds).padStart(minutes > 0 ? 2 : 1, '0') + 'S';
        this.countdown = result;
      });
  }
}
