import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TabsComponent } from './components/tabs/tabs.component';
import { InfoComponent } from './components/info/info.component';
import { FooterComponent } from './components/footer/footer.component';
import { WinningsComponent } from './components/winnings/winnings.component';
import { SpinmachineComponent } from './components/spinmachine/spinmachine.component';
import { NextSpinInfoComponent } from './components/next-spin-info/next-spin-info.component';
import { LottieMachineComponent } from './components/lottiemachine/lottie-machine.component';
import { CharityComponent } from './components/charity/charity.component';
import { TranslationPipe } from './pipes/translation.pipe';
import { LottieComponent } from 'ngx-lottie';

@NgModule({
  declarations: [
    TabsComponent,
    InfoComponent,
    FooterComponent,
    WinningsComponent,
    SpinmachineComponent,
    NextSpinInfoComponent,
    LottieMachineComponent,
    TranslationPipe,
    CharityComponent,
  ],
  imports: [FormsModule, CommonModule, LottieComponent],
  exports: [
    TabsComponent,
    InfoComponent,
    FooterComponent,
    WinningsComponent,
    SpinmachineComponent,
    NextSpinInfoComponent,
    LottieMachineComponent,
    TranslationPipe,
  ],
})
export class SharedModule {}
