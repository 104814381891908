export function emitIframeMessage(
  type:
    | 'close'
    | 'upsell-1'
    | 'terms-conditions'
    | 'game-rules'
    | 'faq'
    | 'deposit',
  payload = {}
) {
  const messageData = {
    type,
    ...payload,
  };
  console.log('emit iframe message', messageData);
  window.parent.postMessage(messageData, '*');
}
