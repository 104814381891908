import { Component, OnInit } from '@angular/core';
import { SessionService } from '@services/session.service';
import { Tabs } from '../../types/tabs';
import { CollectiblesService } from '@services/collectibles.service';
import {
  CollectibleGoal,
  CollectibleGoalType,
} from '../../types/collectible-goal';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { map, tap } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  readonly tabs = Tabs;
  selectedTab$ = this.session.selectedTab$;
  hidePlayTab$?: Observable<boolean>;

  constructor(
    private session: SessionService,
    private collectiblesService: CollectiblesService
  ) {}

  ngOnInit(): void {
    this.hidePlayTab$ = combineLatest([
      this.session.isMobile$.pipe(map(isMobile => !isMobile)),
      this.collectiblesService.collectibles$,
    ]).pipe(
      untilDestroyed(this),
      map(([isDesktop, collectibles]) => {
        return !!(
          isDesktop &&
          collectibles.length > 0 &&
          collectibles.filter(
            (c: CollectibleGoal) =>
              c.goal.type === CollectibleGoalType.COLLECTIBLE
          ).length === 0
        );
      }),
      tap(hidePlayTab => {
        if (hidePlayTab) {
          this.selectTab(Tabs.INFO);
        }
      })
    );
  }

  selectTab(tab: Tabs) {
    this.session.setTab(tab);
  }
}
