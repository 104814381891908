import { Component, HostBinding, OnInit } from '@angular/core';
import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { getCurrencySymbol } from '@angular/common';
import { SessionService } from '@services/session.service';
import { Asset } from '@longnecktech/splash-commons-fe';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { SpinService } from '@services/spin.service';

interface Reel {
  index: number;
  icons: Asset[];
}

@UntilDestroy()
@Component({
  selector: 'app-spinmachine',
  templateUrl: './spinmachine.component.html',
  styleUrls: ['./spinmachine.component.scss'],
  animations: [
    trigger('bounceIn', [
      transition(':enter', [
        animate(
          '1000ms',
          keyframes([
            style({ transform: 'scale(0)', offset: 0 }),
            style({ transform: 'scale(0)', offset: 0.5 }),
            style({ transform: 'scale(1.1)', offset: 0.9 }),
            style({ transform: 'scale(1)', offset: 1 }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class SpinmachineComponent implements OnInit {
  @HostBinding('@bounceIn') get animate() {
    return true;
  }

  showHeader = true;
  currencyStr = '';
  theme$ = this.session.theme$;
  spin$ = this.spinService.spin$;
  isGBCountry$ = this.session.isGBCountry$;
  reels: Reel[] = [
    {
      index: 1,
      icons: [],
    },
    {
      index: 2,
      icons: [],
    },
    {
      index: 3,
      icons: [],
    },
  ];

  trackIconFn(iconIdx: number, icon: Asset): string {
    return `${iconIdx}-${icon.uuid}`;
  }

  constructor(
    private session: SessionService,
    private spinService: SpinService,
  ) {}

  ngOnInit(): void {
    this.initCurrency();
    this.initIcons();
    this.updateIconsOnSpin();
  }

  private initCurrency(): void {
    this.session.user$
      .pipe(
        filter((user) => !!user),
        untilDestroyed(this),
      )
      .subscribe((user) => {
        this.currencyStr =
          user!.currency !== null
            ? getCurrencySymbol(user!.currency, 'narrow')
            : '';
      });
  }

  private shuffleArray(icons: Asset[]): Asset[] {
    const copyIcons = icons.slice();
    for (let i = copyIcons.length - 1; i > 0; i--) {
      // Generate a random index between 0 and i
      const randomIndex = Math.floor(Math.random() * (i + 1));

      // Swap elements at i and randomIndex
      const temp = copyIcons[i];
      copyIcons[i] = copyIcons[randomIndex];
      copyIcons[randomIndex] = temp;
    }
    return copyIcons;
  }

  private initIcons(): void {
    this.session.icons$
      .pipe(
        filter((icons) => !!icons.length),
        untilDestroyed(this),
      )
      .subscribe((data) => {
        const icons = [];
        for (let i = 0; i < 57; i++) {
          icons.push(data[Math.floor(Math.random() * data.length)]);
        }
        let tries = 0;
        let allDisplayedIconAreTheSame = true;
        do {
          this.reels[0].icons = this.shuffleArray(icons);
          this.reels[1].icons = this.shuffleArray(icons);
          this.reels[2].icons = this.shuffleArray(icons);
          allDisplayedIconAreTheSame =
            this.reels[0].icons[icons.length - 2].uuid ===
              this.reels[1].icons[icons.length - 2].uuid &&
            this.reels[1].icons[icons.length - 2].uuid ===
              this.reels[2].icons[icons.length - 2].uuid;
          tries++;
        } while (tries < 100 && allDisplayedIconAreTheSame);
      });
  }

  private updateIconsOnSpin(): void {
    this.spin$
      .pipe(
        untilDestroyed(this),
        filter((spin) => !!spin),
      )
      .subscribe((spin) => {
        this.reels[0].icons[1] = spin!.icon1;
        this.reels[1].icons[1] = spin!.icon2;
        this.reels[2].icons[1] = spin!.icon3;
      });
  }
}
