import { Component, OnInit } from '@angular/core';
import { AnimationOptions, BMCompleteLoopEvent } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';

import { SessionService } from '@services/session.service';
import { LottieFile } from '../../types/lottie-file';
import { Asset } from '@longnecktech/splash-commons-fe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { SpinService } from '@services/spin.service';

interface Reel {
  icons: Asset[];
}

@UntilDestroy()
@Component({
  selector: 'app-lottie-machine',
  templateUrl: './lottie-machine.component.html',
  styleUrls: ['./lottie-machine.component.scss'],
})
export class LottieMachineComponent implements OnInit {
  options: AnimationOptions | undefined;
  reels: Reel[] = [
    {
      icons: [],
    },
    {
      icons: [],
    },
    {
      icons: [],
    },
  ];

  constructor(
    private session: SessionService,
    private spinService: SpinService,
  ) {}

  ngOnInit(): void {
    this.initIcons();
    this.updateIconsOnSpin();
  }

  animationCreated(e: AnimationItem) {
    console.log('animation created', e);
  }

  loopCompleted(e: BMCompleteLoopEvent) {
    console.log('loop completed', e);
  }

  private shuffleArray(icons: Asset[]): Asset[] {
    const copyIcons = icons.slice();
    for (let i = copyIcons.length - 1; i > 0; i--) {
      // Generate a random index between 0 and i
      const randomIndex = Math.floor(Math.random() * (i + 1));

      // Swap elements at i and randomIndex
      const temp = copyIcons[i];
      copyIcons[i] = copyIcons[randomIndex];
      copyIcons[randomIndex] = temp;
    }
    return copyIcons;
  }

  private initIcons(): void {
    this.session.icons$
      .pipe(
        filter((icons) => !!icons.length),
        untilDestroyed(this),
      )
      .subscribe((data) => {
        const icons = [];
        for (let i = 0; i < 57; i++) {
          icons.push(data[Math.floor(Math.random() * data.length)]);
        }
        this.reels[0].icons = this.shuffleArray(icons);
        this.reels[1].icons = this.shuffleArray(icons);
        this.reels[2].icons = this.shuffleArray(icons);
        const animationData = JSON.parse(
          JSON.stringify(this.session.spinMachineLottie),
        ) as LottieFile;

        ['image_0', 'image_1', 'image_2', 'image_3', 'image_4'].forEach(
          (img, idx) => {
            animationData.assets.find((asset) => asset.id === img)!.p =
              this.reels[0].icons[idx].url;
          },
        );
        this.options = {
          animationData: animationData,
          loop: false,
          autoplay: true,
        };
      });
  }

  private updateIconsOnSpin(): void {
    this.spinService.spin$
      .pipe(
        untilDestroyed(this),
        filter((spin) => !!spin),
      )
      .subscribe((spin) => {
        this.reels[0].icons[1].url = spin!.icon1.url;
        this.reels[1].icons[1].url = spin!.icon2.url;
        this.reels[2].icons[1].url = spin!.icon3.url;
      });
  }
}
