import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  tap,
  take,
  map,
  catchError,
  withLatestFrom,
  filter,
} from 'rxjs/operators';
import { UserAction } from '@longnecktech/splash-commons-fe';
import { SessionService } from './session.service';
import { Game } from '@shared/types/game';
import { NextSpinInfo } from '@shared/types/next-spin-info';
import { environment } from '@environment';
import { Spin } from '@shared/types/spin';
import { CollectiblesService } from './collectibles.service';
import { Observable, BehaviorSubject, throwError, delay } from 'rxjs';
import { DELAY_ON_SPIN } from '@shared/constants/common.const';

@Injectable({
  providedIn: 'root',
})
export class SpinService {
  private _spinInfo = new BehaviorSubject<NextSpinInfo | undefined>(undefined);
  private _showNextSpinInfo = new BehaviorSubject<boolean>(false);
  private _spin = new BehaviorSubject<Spin | undefined>(undefined);
  private _spinClicked = new BehaviorSubject<boolean>(false);

  spinInfo$ = this._spinInfo.asObservable();
  allowedToSpin$ = this.spinInfo$.pipe(map((info) => !!info?.allowedToSpin));
  showNextSpinInfo$ = this._showNextSpinInfo.asObservable();
  spin$ = this._spin.asObservable();
  spinClicked$ = this._spinClicked.asObservable();
  isWon$ = this.spin$.pipe(
    filter((spin) => !!spin),
    map((spins) => !!spins!.winnings.length),
  );

  constructor(
    private http: HttpClient,
    private session: SessionService,
    private collectibleService: CollectiblesService,
  ) {}

  fetchSpinInfo(game: Game): Observable<NextSpinInfo> {
    return this.http
      .get<NextSpinInfo>(
        environment.backendUrl + '/api/spinmachine/spin/' + game.uuid,
      )
      .pipe(
        tap((data) => {
          this.setSpinInfo({
            ...data,
            startTs: new Date().getTime(),
          });
        }),
      );
  }

  onSpin(game: Game): Observable<Spin> {
    this._spinClicked.next(true);
    return this.http
      .post<Spin>(
        environment.backendUrl + '/api/spinmachine/spin/' + game.uuid,
        {},
      )
      .pipe(
        withLatestFrom(this.session.user$.pipe(take(1))),
        map(([spin, user]) => {
          if (game!.spinPrize) {
            user!.balance -= game!.spinPrize;
          }
          this.setSpin(spin);
          this.setSpinInfo({
            ...spin.nextSpinInfo,
            startTs: new Date().getTime(),
          });
          return spin;
        }),
        delay(DELAY_ON_SPIN),
        tap((spin) => {
          this.collectibleService.updateCollectibles(
            spin.updatedCollectibles,
            true,
          );
        }),
        catchError((error: Error) => {
          this.session
            .sendAction(UserAction.TRANSACTION_ERROR, error)
            .pipe(take(1))
            .subscribe();
          return throwError(() => new Error(error.message));
        }),
      );
  }

  setSpinInfo(info: NextSpinInfo) {
    this._spinInfo.next(info);
  }

  updateShowNextSpinInfo(data: boolean) {
    this._showNextSpinInfo.next(data);
  }

  setSpin(data: Spin | undefined): void {
    this._spin.next(data);
  }
}
