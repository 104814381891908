import { Injectable } from '@angular/core';
import confetti from 'canvas-confetti';
import { of, delay } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfettiService {
  private isReset = false;

  shoot(angle: number, delayTime: number): void {
    this.isReset = false;
    of({
      angle: angle,
      spread: 40,
      particleCount: 100,
      origin: {
        y: 0.6,
      },
    })
      .pipe(
        take(1),
        delay(delayTime),
        filter(() => !this.isReset),
      )
      .subscribe(() => {
        try {
          confetti({
            angle: angle,
            spread: 40,
            particleCount: 100,
            origin: {
              y: 0.6,
            },
          });
        } catch (e) {
          // noop, confettijs may not be loaded yet
        }
      });
  }

  reset(): void {
    this.isReset = true;
    confetti.reset();
  }
}
