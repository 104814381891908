<div class="info-title">
  <span class="header">{{ 'info.howToPlay' | translate }}</span>
  <span class="p1">
    {{ 'info.spinsPerDay' | translate : [(spinsPerDay$ | async)!] }}
  </span>
</div>
<div class="prizes">
  @for (collectible of collectibles$ | async; track collectible) {
    <div class="prize-row">
      <div class="prize-icon-container">
        <img class="symbol-icon" [src]="collectible.goal.icon.url" alt="Icon" />
      </div>
      <div class="prize-info">
        <span [innerHTML]="collectible.goal.prizeDesc"></span>
      </div>
    </div>
  }
</div>
@if (charityValue$ | async) {
  <app-charity></app-charity>
}
@if (theme$ | async; as theme) {
  @if (theme.featuredGameName && theme?.featuredGameAsset?.url) {
    <div class="featured-game-container">
      <img
        class="game-icon"
        [src]="theme.featuredGameAsset.url"
        alt="Game Icon"/>
      <div class="featured-info">
        <span class="featured-title">{{ 'info.featuredGame' | translate }}</span>
        <span class="featured-game">{{ theme.featuredGameName }}</span>
      </div>
    </div>
  }
}
@if ('info.shortRules' | translate) {
  <div class="info-container">
    @if (isGBCountry$ | async) {
      <div class="info-labels">
        {{ 'info.shortRules' | translate }}
      </div>
    } @else {
      <div class="info-labels">
        {{ 'info.shortRulesCA' | translate }}
      </div>
    }
  </div>
}
