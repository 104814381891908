<div class="container">
  <span class="title">{{ 'charity.title' | translate }}</span>
  <span class="message">{{ 'charity.message' | translate }}</span>
  <div class="collectible">
    <span class="count"
    >${{ (charityValue$ | async) | number: '1.0-1' }}</span
    >
    <div class="progress-wrapper">
      <div
        class="progress-bar"
        [style.width]="(width$ | async) + '%'"></div>
    </div>
    <svg width="24" height="24"
         viewBox="0 0 24 24"
         class="prize-icon"
         fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M7.5 3C7.10218 3 6.72064 3.15804 6.43934 3.43934C6.15804 3.72064 6 4.10218 6 4.5C6 4.89782 6.15804 5.27936 6.43934 5.56066C6.72064 5.84196 7.10218 6 7.5 6H10.654C10.5319 5.68061 10.3774 5.32942 10.1863 4.98192C9.55732 3.83834 8.6939 3 7.5 3ZM4 4.5C4 5.02384 4.11743 5.53557 4.33772 6H2C1.44772 6 1 6.44772 1 7V12C1 12.5523 1.44772 13 2 13H3V22C3 22.5523 3.44772 23 4 23H12H20C20.5523 23 21 22.5523 21 22V13H22C22.5523 13 23 12.5523 23 12V7C23 6.44772 22.5523 6 22 6H19.6623C19.8826 5.53557 20 5.02384 20 4.5C20 3.57174 19.6313 2.6815 18.9749 2.02513C18.3185 1.36875 17.4283 1 16.5 1C14.1939 1 12.8073 2.66166 12.0613 4.01808C12.0405 4.0559 12.0201 4.09369 12 4.13142C11.9799 4.09369 11.9595 4.0559 11.9387 4.01808C11.1927 2.66166 9.8061 1 7.5 1C6.57174 1 5.6815 1.36875 5.02513 2.02513C4.36875 2.6815 4 3.57174 4 4.5ZM13.346 6H16.5C16.8978 6 17.2794 5.84196 17.5607 5.56066C17.842 5.27936 18 4.89782 18 4.5C18 4.10218 17.842 3.72064 17.5607 3.43934C17.2794 3.15804 16.8978 3 16.5 3C15.3061 3 14.4427 3.83834 13.8137 4.98192C13.6226 5.32942 13.4681 5.68061 13.346 6ZM13 8V11H20H21V8H16.5H13ZM7.5 8H11V11H4H3V8H7.5ZM13 21H19V13H13V21ZM11 13V21H5V13H11Z"/>
    </svg>
    <span class="prize"
    >${{ (charityTarget$ | async) | number: '1.0-1' }}</span
    >
  </div>
</div>
