import { Component } from '@angular/core';
import { SessionService } from '@services/session.service';
import { map, filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-charity',
  templateUrl: './charity.component.html',
  styleUrl: './charity.component.scss',
})
export class CharityComponent {
  charityValue$ = this.session.game$.pipe(
    filter((game) => !!game),
    map((game) => game!.charityValue),
  );
  charityTarget$ = this.session.game$.pipe(
    filter((game) => !!game),
    map((game) => game!.charityTarget),
  );
  width$ = combineLatest([this.charityValue$, this.charityTarget$]).pipe(
    filter(
      ([charityValue, charityTarget]) =>
        charityValue !== null &&
        charityValue !== undefined &&
        charityTarget !== null &&
        charityTarget !== undefined,
    ),
    map(
      ([charityValue, charityTarget]) => (charityValue / charityTarget) * 100,
    ),
  );

  constructor(private session: SessionService) {}
}
